<template>

  <meta-teleport/>

  <json-ld v-if="!user_id" />


    <!-- About Car -->
    <about-car/>




  <!-- Photos MD -->
  <!--  row-cols-1 row-cols-md-3 g-4-->
  <md-photos/>

  <sm-photo-slider v-if="Object.keys(car.data).length > 0" :car="car.data"/>

  <car-inspection/>

  <!--  HTML / <p> -->
  <description-block v-if="car.data.description" :description="car.data.description"/>

  <div class="row mb-2" v-if="car.data && car.data.private_listing == 0 && Object.keys(car.data).length > 0">

    <div class="col-12 text-end">

      <share-offer :car="car.data"/>

    </div>

  </div>

  <car-equipment :car="car"/>


  <!--  v-if="car.data.price || car.data.msrp_price"-->
<!--  Car Price-->
  <car-price/>

  <span class="text-danger ms-3 mt-3 d-inline-block" v-if="car.data.private_listing">Off market: private listing. Only for verified brokers.</span>


  <!-- Comments -->
  <div class="container mt-3 h-auto" v-if="car.data && Object.keys(car.data).length > 0">


    <comment-list :comments="car.data.comments"/>

    <!--    <img class="ms-2 animate__animated animated_footer" src="/img/icons/disquss_1.png " width="70" :alt="'Discussions ' + car.data.model.brand.name +' '+ car.data.model.model ">-->

    <post-comment :offer_id="id"/>
  </div>


  <div class="container mt-3 text-danger" v-if="!car.data && Object.keys(car.data).length == 0 ">
    {{ car_languages[selected_language].private_listing }}, only for verified brokers.
  </div>


  <!-- Same offer by model -->
  <div class="container mt-3" v-if="car.data && Object.keys(car.data).length > 0">
    <same-offers/>
  </div>

  <!-- HOT OFFERS -->
  <div class="container mt-5" v-if="car.data && Object.keys(car.data).length > 0">
    <suggestion-offers v-if="car.data && Object.keys(car.data).length > 0"/>
  </div>

  <!-- Trade-in Modal-->
  <trade-in-modal/>



</template>

<script setup>
import {onBeforeMount, onMounted, ref, onUnmounted, watch, onUpdated} from "vue";
import {userState} from '@/composables/login';
import {Cars} from '@/composables/profile/cars';
import {Helpers} from '@/composables/helpers';
//import {Views} from '@/composables/views';
//import {TranslateCustomerRegistration} from '@/composables/translate/customer_registration';
import {Languages} from '@/composables/Languages';
//import {useRouter} from "vue-router";
//import {User} from "@/composables/profile/user";
//import moment from 'moment'

import MetaTeleport from '@/components/offer/MetaTeleport.vue';
import ShareOffer from '@/components/offer/ShareOffer.vue';
//import FormButtonRequest from '@/components/offer/request/FormButtonRequest.vue';
//import FormRequestModal from '@/components/offer/request/FormRequestModal.vue';
//import FinanceInfo from '@/components/offer/FinanceInfo.vue';
//import LeasingInfo from '@/components/offer/LeasingInfo.vue';

import MetaTeleportNotFound from '@/components/inc/MetaTeleportNotFound.vue';
import SmPhotoSlider from '@/components/offer/SmPhotoSlider.vue';
//import ProviderInfo from '@/components/offer/ProviderInfo.vue';
import SameOffers from '@/components/offer/SameOffers.vue';
import SuggestionOffers from '@/components/offer/SuggestionOffers.vue';
// import LetterModal from '@/components/offer/documents/LetterModal.vue';
// import NdaModal from '@/components/offer/documents/NdaModal.vue';
// import ProofOfFundsModal from '@/components/offer/documents/ProofOfFundsModal.vue';
// import ButtonAddCar from '@/components/offer/selected_car/ButtonAddCar.vue';
import TradeInModal from '@/components/offer/trade_in/TradeInModal.vue';


import CommentList from '@/components/offer/comment/CommentList.vue';
//import StartDeal from '@/components/offer/broker_to_broker/StartDeal.vue';
import PostComment from '@/components/offer/comment/PostComment.vue';
import CarInspection from "@/components/offer/inspection/CarInspection.vue";
import DescriptionBlock from "@/components/offer/DescriptionBlock.vue";
//import CheckAvailability from "@/components/offer/CheckAvailability.vue";
import MdPhotos from '@/components/offer/MdPhotos.vue';
import CarEquipment from '@/components/offer/CarEquipment.vue';
import JsonLd from '@/components/offer/JsonLd.vue';
import AboutCar from "@/components/offer/AboutCar.vue";
import CarPrice from "@/components/offer/CarPrice.vue";




//import HotOffersMd from '@/components/main_page/HotOffersMd.vue';
//import HotLeaseMd from '@/components/main_page/HotLeaseMd.vue';
//import ContactForm from "@/components/offer/request/ContactForm.vue";

//const {translate_customer_registration} = TranslateCustomerRegistration()
const {selected_language, car_languages} = Languages()

const {user_id, user_type} = userState()

//const router = useRouter()

const {activateMasonry} = Helpers()

//const {view, storeView} = Views()

const {getCarOffer, car, getPrivateCarOffer, getHotOffers, cars_hot_offers} = Cars() // getHotLease, cars_hot_offers, cars_hot_lease

onBeforeMount(() => {


  dependOnUserType()

  refreshOffer()

  if(Object.keys(cars_hot_offers.data).length == 0){

    getHotOffers()
  }


  // setTimeout(async () => {
  //
  //   if (car.data && !car.data.lease_term_months) {
  //
  //     if (Object.keys(cars_hot_offers.data).length == 0) {
  //
  //       await getHotOffers()
  //     }
  //
  //   } else {
  //
  //     if (Object.keys(cars_hot_lease.data).length == 0) {
  //       await getHotLease()
  //     }
  //   }
  //
  //
  //   view.data.car_id = props.id
  //   await storeView()
  //
  // }, 1000)


})

onUnmounted(() => {

  clearInterval(refresh.value)
})


onMounted(() => {

  window.scrollTo(0, 0)

})

const dependOnUserType = () => {


  if (user_type.value > 0) {

    getPrivateCarOffer(props.id)

  } else {

    getCarOffer(props.id)
  }
}

const refresh = ref()
const refreshOffer = () => {

  refresh.value = setInterval(() => {

    //console.log('+')
    dependOnUserType()


  }, 30000)
}

onUpdated(() => {

  activateMasonry()
})

const props = defineProps(['id'])

watch(props, () => { // newVal, oldVal


      //scroll to top
      window.scrollTo(0, 0)

      dependOnUserType()

    } //, {deep: true, immediate: true}
);


</script>

<style scoped>
.bg_price {

  background-color: #8EC5FC;
  background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

}

.maxHeight {
  max-height: 100px;
}
</style>

