<template>


  <template v-if="user_type == 2">

    <!-- Button trigger modal -->
    <button @click="infoSubscription()"
            type="button"
            class="btn btn-dark rounded-pill comfortaa btn-sm float-end" data-bs-toggle="modal" data-bs-target="#subscriptionModal">
      Get Contacts
    </button>

    <!-- Modal -->
    <div class="modal fade" id="subscriptionModal" tabindex="-1" aria-labelledby="subscriptionModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content rounded-4">

          <div class="modal-header pt-1 pe-0 ps-0">

            <div class="container p-0 m-0">

              <div class="row mt-1 me-1 ms-0">


                <div class="col-10">

                  <span class="h4 m-0 p-0" id="subscriptionModalLabel"> SUBSCRIPTION </span>

                  <br>

                  <span v-if="subscription_active"
                  class="text-muted animate__animated animate__fadeInUp d-inline-block">active </span>
                  <span class="text-muted" v-else>inactive</span>


                </div>

                <div class="col-2 text-end pt-2">

                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                </div>

              </div>

            </div>


          </div>

          <div class="modal-body">

            <request-subscription v-if="!subscription_active" />

            <car-details v-if="subscription_active" />

          </div>

          <!--        <div class="modal-footer">-->
          <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
          <!--          <button type="button" class="btn btn-primary">Save changes</button>-->
          <!--        </div>-->

        </div>
      </div>
    </div>

  </template>

</template>

<script setup>
import {computed} from "vue";
import {userState} from '@/composables/login';
import {Subscription} from '@/components/offer/broker_subscrption/subscription'
import moment from "moment"

import RequestSubscription from "@/components/offer/broker_subscrption/RequestSubscription.vue";
import CarDetails from "@/components/offer/broker_subscrption/CarDetails.vue";


const {user_type} = userState()

const {subscription, infoSubscription} = Subscription()

const subscription_active = computed(() => {
  return subscription.value && subscription.value.id ?  subscription.value.date_end && moment(subscription.value.date_end).isAfter(moment()) : false
})
</script>