<template>

  <!-- Main page -->

  <teleport to="head">

    <title>Hot Auto Market: services marketplace</title>

    <meta name="description" content="Car marketplace that provides B2B2C services">

    <link rel="canonical" :href="'https://hotautomarket.com/'"/>

  </teleport>

  <!-- Get started -->
  <!--  <get-started />-->

<!--  <new-utp/>-->
  <search-form-new/>


  <h3 class="mt-3 fw-bold text-danger oswald">
    {{translate_main_page[selected_language].hot_offers}}
  </h3>

  <hot-offers-sm />
  <hot-offers-md />

    <!-- Wanted Block -->
    <wanted-block/>

  <!-- New Brokers  -->
  <new-dealers v-if="cars.data && Object.keys(cars.data).length > 0" />

<!--  <h3 class="mt-3 fw-bold text-danger oswald" v-if="!loading_car">-->
<!--    HOT LEASE-->
<!--  </h3>-->
<!--  <hot-lease-sm/>-->
<!--  <hot-lease-md/>-->

  <blog-cards-main-page/>


  <div class="container mb-5">

    <!-- Photographed -->
    <photographed-car/>

  </div>

  <div class="row bg-dark text-white rounded mt-3 pb-2 bg-gradient shadow m-1" v-if="Object.keys(cars.data).length > 0">

    <div class="col-12 col-md-8 mt-2">

      <span class="h1 fw-bold">
        {{translate_main_page[selected_language].search.header}}</span>

      <br><span class="text-light ms-1">{{translate_main_page[selected_language].search.prefix}}</span>

    </div>

    <div class="col-12 col-md-4 mt-2 pt-3" id="search_result">


      <search-form/>
    </div>


  </div>


  <load-line class="mt-3" v-if="loading_car"/>

  <br>

  <span class="ms-2 d-inline-block" v-if="cars.data.total == 0">
    Unfortunately, we don't have any records available at the moment.

    <span class="btn btn-secondary rounded-4" @click="search.data = {}, search_model = null">Okay</span>

  </span>





  <!-- Stock infinity -->
  <cars-list :id="id" />





</template>

<script setup>
import {onBeforeMount, watch} from 'vue';
import {Cars} from '@/composables/profile/cars';
//import {Helpers} from '@/composables/helpers';
import {Languages} from '@/composables/Languages';
import {TranslateMainPage} from '@/composables/translate/main_page';
import {MainPage} from '@/composables/main_page';
import {Blog} from '@/composables/blog';

import SearchForm from '@/components/inc/SearchForm.vue'
//import GetStarted from '@/components/main_page/GetStarted.vue'

import HotOffersSm from '@/components/main_page/HotOffersSm.vue'
import HotOffersMd from '@/components/main_page/HotOffersMd.vue'

//import PriceType from '@/components/main_page/PriceType.vue'
import NewDealers from '@/components/main_page/NewDealers.vue'
//import PaginateLinks from '@/components/inc/PaginateLinks.vue'
import LoadLine from '@/components/inc/LoadLine.vue'
import BlogCardsMainPage from "@/components/blog/BlogCardsMainPage.vue";
import NewUtp from "@/components/main_page/NewUtp.vue";
import WantedBlock from "@/components/main_page/WantedBlock.vue";
import CarsList from "@/components/main_page/stock_scrolled/CarsList.vue";
import PhotographedCar from "@/components/main_page/PhotographedCar.vue";

import SearchFormNew from '@/components/search_new/SearchForm.vue'

//import CommentItem from '@/components/main_page/CommentItem.vue';


//const {user_id} = userState()

const {getMain, main} = MainPage()

const {blog_list} = Blog()

const {selected_language} = Languages()
const {translate_main_page} = TranslateMainPage()

//const {activateMasonry} = Helpers()

//import bootstrap from "bootstrap";

//const router = useRouter()

const {car, cars, getListCars, loading_car, cars_hot_offers, search, search_model, new_dealers, cars_models} = Cars() // getHotLease


// onUpdated( () =>{
//
//   activateMasonry()
// })

onBeforeMount(async () => {

  // console.log(navigator.languages)
  // console.log(navigator.language)

  car.data = {}
  cars.data = {}

  if(Object.keys(main.value).length == 0){

    await getMain()
  }

  cars_hot_offers.data = main.value.hot
  cars.data = main.value.latest
  new_dealers.data = main.value.new_dealers
  blog_list.value = main.value.blog_latest
  cars_models.data = main.value.car_models

  //getListCars('')

  //getNewDealers()

  //getHotLease()

  window.scrollTo(0, 0)
})




const props = defineProps(['id', 'page'])

watch(props, async () => { // newVal, oldVal


  //console.log('Router:', newVal, oldVal)
  document.getElementById('search_result').scrollIntoView();


  await getListCars(search.data.text)



      //scroll to top
      //window.scrollTo(0, 0)


      //access.scrollIntoView();

    } //, {deep: true, immediate: true}
);
</script>

<style scoped>

.sss {
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);

}

.vvv {
  background: #ECE9E6; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

</style>