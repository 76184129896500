<template>

  <div class="bg rounded-bottom-4">

    <div class="row">

      <div class="col-12 text-center pe-2 ps-3">

        <h1 class="mt-3 fw-bold text-light comfortaa">Best Offers From Verified Brokers And Owners</h1>

      </div>

      <div class="col-12 text-center pe-4 ps-4">


        <div class="col-md-5 col-12 mb-3 text-center search-container">

          <div class="input-group rounded-pill">

            <input type="search" v-model="search_guess" @input="guessCar()" class="form-control form-control-lg" placeholder="Search ..." aria-label="Search premium cars">

<!--            <span v-if="search_guess" class="input-group-text bg-white animate__animated animate__fadeInLeft handLink" style="width: 21%"-->
<!--                  @click="price_active = !price_active, !price_active ? search_price = 0 : '' ">-->
<!--              Price-->
<!--            </span>-->

<!--            <span v-if="search_guess" @click="years_active = !years_active"-->
<!--                  class="input-group-text bg-white text-center  animate__animated animate__fadeInLeft handLink" style="width: 20%">-->
<!--              Year-->
<!--            </span>-->

            <span v-if="!search_guess" class="input-group-text bg-white" style="width: 45px">
            <img src="/img/icons/magnifier_black.svg" class="magnifier-icon" alt="search-icon" width="26"/>
          </span>
          </div>

        </div>

      </div>


      <div class="col-12 text-center">

        <guess-list/>

      </div>


    </div>

  </div>


</template>

<script setup>
import {Searching} from '@/components/search_new/search'
import GuessList from "@/components/search_new/GuessList.vue";


const {search_guess, guessCar, price_active, years_active} = Searching()

import {Cars} from "@/composables/profile/cars";
//import {watch} from "vue";

const {search, search_model, search_price} = Cars()

// watch(search_guess, () => { // newVal, oldVal
//
//   if(!search_guess.value){
//
//     search.data = {}
//     search_model.value = ''
//   }
//
// });

</script>

<style scoped>

.bg {
  min-height: 230px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)),
  url('/img/alfa.jpg');
  background-position: center bottom;
  background-repeat: no-repeat; /* Optional: prevents the image from tiling */
  background-size: cover; /* Optional: scales the image to cover the element */
}


.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  width: 100%;
  padding-right: 30px; /* Add padding to make space for the icon */
  box-sizing: border-box; /* Ensures padding doesn't affect the width */
}

.magnifier-icon {
  position: absolute;
  right: 10px; /* Adjust for desired distance from the right edge */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the icon */
  pointer-events: none; /* Make sure the icon doesn't block input interactions */
  font-size: 16px; /* Adjust size as needed */
  color: #888; /* Optional: Adjust color */
}
</style>
