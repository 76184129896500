<template>

  <teleport v-if="car.data && car.data.photos && car.data.photos[0] && car.data.sold == 0 && car.data.buy_leasing == 0 && car.data.price > 0" to="head">

    <!-- https://chatgpt.com/c/6758c5ac-52c4-800b-9216-a7fac4162f63 -->

    <component :is="'script'" type="application/ld+json" v-if="car.data && car.data.model && car.data.model.brand && car.data.model.brand.name">
      {{jsonLd()}}
    </component>

  </teleport>

<!--  {{car.data.model.brand.name}}-->
</template>

<script setup>
//const props = defineProps(['car'])
import {Cars} from "@/composables/profile/cars";

const {car} = Cars()

const description = (text) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  return JSON.stringify(doc.body.innerText) || ''; // Extracts text content
}

const priceValidUntil = (d) => {

  if(!d){
    return
  }

  const datePart = d.split(' ')[0];

// Convert to a Date object
  const dateObject = new Date(datePart);

// Add 2 years
  dateObject.setFullYear(dateObject.getFullYear() + 2);

// Convert back to a string in YYYY-MM-DD format
  return dateObject.toISOString().split('T')[0];
}

const jsonLd = () =>{

  if(car.data && car.data.model && car.data.model.brand){

  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": car.data.year + (car.data && car.data.model.brand.name ? ' ' + car.data.model.brand.name : '') + (car.data.model && car.data.model.model ? ' ' + car.data.model.model : '') +  (car.data.model_addition ? ' ' + car.data.model_addition : ''),

    "image": [
      car.data.photos ? car.data.photos.slice(0, 5).map(photo => `https://hotautomarket.com/storage/cars/${photo.pfile}`).join(', ') : ''
    ],

    "description": description(car.data.description),

    "brand": {
      "@type": "Brand",
      "name": car.data.model && car.data.model.brand ? car.data.model.brand.name : ''
    },
    "model": car.data.model? car.data.model.model : '',
    "vehicleModelDate": car.data.year,
    "sku": car.data.id,
    "category": "Cars",
    "offers": {
      "@type": "Offer",
      "url": "https://hotautomarket.com/offer/" + car.data.id,
      "priceCurrency": "USD",
      "price": car.data.price,
      "priceValidUntil": priceValidUntil(car.data.updated_at),
      "itemCondition": "https://schema.org/UsedCondition",
      "availability": "https://schema.org/InStock",

      "hasMerchantReturnPolicy": {
        "@type": "MerchantReturnPolicy",
        "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
        "merchantReturnDays": 7,
        "applicableCountry": ["US","EU","AE","CA","JP","CH","AU","UK","SG","HK"],

        "returnMethod": "https://schema.org/ReturnByMail",
        "returnFees": "https://schema.org/ReturnShippingFees",
        "returnPolicyCountry": ["US","EU","AE","CA","JP","CH","AU","UK","SG","HK"],
        "description": "Products can be returned via Air Logistics. Return shipping costs are the responsibility of the customer.",

        "returnShippingFeesAmount": {
          "@type": "MonetaryAmount",
          "value": "20000",
          "currency": "USD"
        }
      },

      "shippingDetails": {
        "@type": "OfferShippingDetails",

        "shippingDestination": [
          {
            "@type": "DefinedRegion",
            "addressCountry": "US"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "EU"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "AE"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "CA"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "JP"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "CH"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "AU"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "UK"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "SG"
          },
          {
            "@type": "DefinedRegion",
            "name": "Air Logistics",
            "addressCountry": "HK"
          }
        ],

        "shippingRate": {
          "@type": "MonetaryAmount",
          "value": "20000",
          "currency": "USD"
        },


        "deliveryTime": {
          "@type": "ShippingDeliveryTime",
          "handlingTime": {

            "@type": "QuantitativeValue",
            "minValue": 1,
            "maxValue": 1,
            "unitCode": "d"
          },

          "transitTime": {
            "@type": "QuantitativeValue",
            "minValue": 1,
            "maxValue": 1,
            "unitCode": "d"
          },

          "cutoffTime": "17:00:00-05:00"
        }

      },


      "applicableCountry": ["US","EU","AE","CA","JP","CH","AU","UK","SG","HK"],

      "seller": {
        "@type": "AutoDealer",
        "name": "Hot Auto Market",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "551 S Military Trl",
          "addressLocality": "West Palm Beach",
          "addressRegion": "FL",
          "postalCode": "33415",
          "addressCountry": "US"
        },

        "telephone": "+1-754-286-1771"
      }
    }
  }
  }

}

//let jsonLd =

/**
 "aggregateRating": {
 "@type": "AggregateRating",
 "ratingValue": "4.7",
 "reviewCount": "123"
 },
 "review": [
 {
 "@type": "Review",
 "author": {
 "@type": "Person",
 "name": "John Doe"
 },
 "datePublished": "2024-12-01",
 "reviewBody": "The 2024 Toyota Camry XSE is an excellent car with a smooth ride, plenty of power, and great safety features.",
 "reviewRating": {
 "@type": "Rating",
 "ratingValue": "5",
 "bestRating": "5"
 }
 },
 {
 "@type": "Review",
 "author": {
 "@type": "Person",
 "name": "Jane Smith"
 },
 "datePublished": "2024-11-15",
 "reviewBody": "Stylish design and reliable performance make this car a top choice.",
 "reviewRating": {
 "@type": "Rating",
 "ratingValue": "4",
 "bestRating": "5"
 }
 }
 ]
 */

/**
 * 103 line
 *
 *            "shippingDestination": {
 *               "@type": "DefinedRegion",
 *               "name": "Air Logistics"
 *             },
 */
</script>