<template>

  <teleport to="head">

    <template v-if="car.data && car.data.id && car.data.model && car.data.model.brand && car.data.model.brand.name">

      <!--    {{car.data.color}} -->
      <!--    {{car.data.buy_credit ? ', finance' : ''}}{{ (car.data.buy_leasing && car.data.buy_cash) ? ' or leasing' : ''}}-->
      <title v-if="car.data && car.data.id">{{car.data.sold ? '' : 'Used'}} {{car.data.model.brand.name}} {{car.data.model.model}} {{car.data.model_addition ? car.data.model_addition : ''}} {{car.data.year}} is {{car.data.sold ? 'sold' : 'for sale for $' + (car.data.price / 1000).toFixed(0)+ 'k'}} - HotAutoMarket</title>

      <!--    <meta name="description" v-if="car.data.description" :content="car.data.description">-->
      <meta name="description"
            :content="'Pre-owned ' + car.data.model.brand.name + (car.data.mileage ? ': mileage ' + car.data.mileage: '') + (car.data.color ? ', and the color is ' + car.data.color : '') + (car.data.color ? ' with ' + car.data.color_interior : '') + ' - Trade-ins are available.'">

      <link v-if="car.data && car.data.id" rel="canonical" :href="'https://hotautomarket.com/offer/'+car.data.id"/>


      <preload-first-image :first_image="car.data.photos[0].pfile" v-if="car && car.data.photos && car.data.photos[0] && car.data.photos[0].pfile"/>

      <!--    <meta-og-snippet :car="car.data" />-->

    </template>


    <!-- No Index -->
    <meta-no-index/>

  </teleport>


</template>

<script setup>
import {Cars} from "@/composables/profile/cars";
import PreloadFirstImage from '@/components/offer/PreloadFirstImage.vue';
import MetaNoIndex from "@/components/offer/MetaNoIndex.vue";

//import MetaOgSnippet from "@/components/offer/MetaOgSnippet.vue";

//const props = defineProps(['car'])


const {car} = Cars() // getHotLease, cars_hot_offers, cars_hot_lease


</script>