import {ref} from "vue";
import axios from "axios";

const search_guess = ref()
const suggestions = ref({})
const price_active = ref(false)
const years_active = ref(false)



export const Searching = () => {


    const guessCar = async () => {

        if(search_guess.value && search_guess.value.length < 2){

            return
        }

        try {

            await axios.post('/api/search-guess', {search: search_guess.value}).then((response) => {

                suggestions.value = response.data
            })

        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    return{
        search_guess, suggestions, guessCar,
        price_active, years_active
    }
}