<template>

  <!--    car && car.data && (!car.data.id || car.data.id == undefined)-->
  <meta v-if="noId" name="robots" content="noindex,follow">

</template>

<script setup>
import {computed} from "vue";
import {Cars} from "@/composables/profile/cars";


const {car, loading_car} = Cars() // getHotLease, cars_hot_offers, cars_hot_lease

const noId = computed(() => {

  if (loading_car.value == false && !car.data.id) {

    document.querySelector('meta[name="robots"][content="index, follow"]')?.remove();

    return true
  }

  return false
})
</script>