<template>

  <!-- for local -->
<!--  <teleport to="head" v-if="local">-->
<!--    <base  href="https://hotautomarket.com/" />-->
<!--  </teleport>-->

  <!--  <div class="my_grad">-->

    <nav-top/>


<!--  <load-profile  v-if="loading" />-->

  <div class="container mt-1 h-auto">
    <RouterView/>
  </div>

  <!--  <app-stores/>-->

<!--  <span v-if="router.currentRoute">-->
<!--    {{router.currentRoute.value.path}}-->
<!--  </span>-->


  <template v-if="router && router.currentRoute && router.currentRoute.value && router.currentRoute.value.fullPath && router.currentRoute.value.path !== '/'">

  <!-- Footer: do not remove div:row -> otherwise scroll on mobile -->
  <div class="m-0 p-0" v-if="footerLate">
    <footer-block/>
  </div>

  </template>

  <support-modal/>

  <!-- Chat -->
  <chat-modal v-if="loggedIn" />

<!--  <subscribe-modal/>-->

<!--  <update-app/>-->

  <counters-analytics/>


  <!-- Video modal: how it works -->
  <video-modal/>

</template>

<script setup>
import {onBeforeMount, ref} from "vue";
import { RouterView } from 'vue-router' // RouterLink,
import {userState} from "@/composables/login";
import {Helpers} from '@/composables/helpers';
import {useRouter} from 'vue-router';

import NavTop from '@/components/inc/NavTop.vue'
import FooterBlock from '@/components/inc/FooterBlock.vue'
import CountersAnalytics from '@/components/inc/CountersAnalytics.vue'
import ChatModal from '@/components/chat/ChatModal.vue'
import SupportModal from '@/components/inc/SupportModal.vue'
import VideoModal from '@/components/how_it_works/VideoModal.vue'

//import SubscribeModal from '@/components/inc/SubscribeModal.vue'

const router = useRouter()

const {removeFbMark} = Helpers();


const {setVariablesAfterRefresh, loggedIn} = userState()


onBeforeMount( () => {

  removeFbMark()

  setVariablesAfterRefresh()

  setTimeout( () => {

    footerLate.value = true
  }, 1000)
})

const footerLate = ref(false)

//const local = ref(false)
</script>


