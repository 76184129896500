<template>

<!--  && moment(subscription.date_end).isAfter(moment())-->
  <button v-if="!subscription.date_end"
          @click="activate()" :disabled="active"
          class="btn btn-danger btn-lg btn-block rounded-pill comfortaa">
    ACTIVATE
  </button>


  <!-- Recurrent payment link -->
  <template v-if="moment(subscription.date_end).isBefore(moment())">
    <a href="https://buy.stripe.com/00g8z61nbajSdGMbIJ" @click="visitPaymentLink()"
       target="_blank" class="btn btn-danger btn-sm btn-block rounded-pill">
      BUY SUBSCRIPTION
    </a>
    <!--    use same email in your account-->


    <br>
    <span class="text-muted me-2 comfortaa">trail period end</span>
  </template>


  <!--  {{subscription.date_end}}-->

</template>

<script setup>
import {ref} from "vue";
import {Subscription} from '@/components/offer/broker_subscrption/subscription';
import {userState} from '@/composables/login';
import moment from "moment"


const {activateTrailSubscription, subscription, infoSubscription, visitPaymentLink} = Subscription()

const {user_id} = userState()

const active = ref(false)

const activate = async () => {

  let prepare = {}

  prepare.user_id = user_id.value
  prepare.date_start = moment().format()
  prepare.date_end = moment().add(1, 'months');


  subscription.value = prepare

  await activateTrailSubscription()

  await infoSubscription()
}

</script>