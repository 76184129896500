<template>

  <button v-if="reveal && !reveal.id"
          @click="getNumber(car.data.user_id), getVin(car.data.id)"
          class="btn btn-secondary rounded-pill mb-3">
    GET DETAILS
  </button>

  <div class="mb-3 animate__animated animate__fadeInDown" v-if="reveal.phone" >

    <div v-if="reveal.business_name">

      {{reveal.business_name}}
    </div>

    {{reveal.name}}: {{reveal.phone}}<br>

    <div class="mt-3" v-if="vin && vin.vin">
      VIN: {{vin.vin}}
    </div>

  </div>





  <img :src="'https://hotautomarket.com/storage/cars/' + car.data.photos[0].pfile"
       class="img-fluid rounded-4">

  &nbsp; {{car.data.model.brand.name}}

  {{car.data.model.model}}

  <small class="text-muted float-end">
    {{car.data.model_addition}} &nbsp;
  </small>

  <br>



</template>

<script setup>
import {Cars} from '@/composables/profile/cars';
import {Reveal} from '@/composables/reveal'
import {onMounted} from "vue";

const {car} = Cars() // getHotLease, cars_hot_offers, cars_hot_lease

const {getNumber, reveal, vin, getVin} = Reveal()



onMounted( () => {

  reveal.value = {}
})
</script>