<template>

  <div class="row mt-3" v-if="car.data && car.data.model">

    <div class="col-12" v-if="car.data.model.brand && car.data.model.brand.name">

      <subscription-modal/>
      <vin-to-sign-up v-if="!user_id && car.data.vin" />

      <!--      {{car.data.photos[0].pfile}}-->

      <!--      loading="lazy"-->
      <img :src="'/img/brand/' +car.data.model.brand.id + '.svg'" style="max-height: 18px"
           class="img-fluid float-start me-1" :alt="car.data.model.brand.name">

      <h1 class="small text-muted oswald fw-light">{{ car.data.model.brand.name }}</h1>


      <!--      <h2 class="h3 text-muted oswald mb-3">{{ car.data.model.brand.name }}</h2>-->

    </div>


    <div class="col-12" v-if="car.data.model.brand && car.data.model.brand.name">


      <h2 class="animate__animated animate__fadeIn">

        <span class="comfortaa fw-bolder text-danger">
          {{ car.data.model.model }}
        </span>
        <span class="text-muted text-uppercase fw-lighter" v-if="car.data.model_addition"> ({{ car.data.model_addition }})</span>

        <span class="fw-bold ms-2" v-if="car.data.sold">(SOLD)</span>

        <button-add-car/>

      </h2>

      <small class="btn btn-outline-danger btn-sm me-3 rounded-pill mb-2" v-if="car.data.private_listing">Private listing</small>

      <span type="button" class="btn btn-outline-secondary btn-sm rounded-pill me-2  mb-2">
        {{ car_languages[selected_language].mileage }}: <span class="badge text-bg-light"> {{ convertToShortForm(car.data.mileage) }} </span>
      </span>

      <span class="fw-bold me-2">{{ car_languages[selected_language].year }}: {{ car.data.year }}</span>

      <span class="text-muted mb-2 d-inline-block" v-if="car.data.color">Color: {{ car.data.color }}</span>
      <span class="text-muted mb-2 d-inline-block" v-if="car.data.pre_order">, Pre order!</span>
      <!--      <span class="text-muted mb-2 d-inline-block">Year: {{ car.data.year }}</span>,-->
      <!--      Mileage: <span class="text-muted mb-2 d-inline-block"> {{ convertToShortForm(car.data.mileage) }}</span>-->


      <!-- Brand new -->
      <br v-if="car.data.brand_new">
      <small class="text-danger ms-2" v-if="car.data.brand_new">{{ car_languages[selected_language].brand_new }}</small>

      <h2 class="text-danger" v-if="car.data.sold">{{ car_languages[selected_language].sold }}</h2>
      <h2 class="text-danger" v-if="car.data.hold">{{ car_languages[selected_language].on_hold }}</h2>


      <br>
      <RouterLink v-if="user_type > 0" class="btn btn-primary rounded-pill mb-3 mt-3 me-3" rel="nofollow" target="_blank" :to="'/api/pdf/' + car.data.id + '/' + user_id">
        {{ car_languages[selected_language].download_pdf }}
      </RouterLink>

      <!--     <span class=""> listed </span>-->


    </div>


    <!-- Provider -->
    <!-- <div class="col-12 mb-3 mt-3 animate__animated animate__fadeIn text-end">-->

    <!-- <provider-info/>-->

    <!-- </div>-->


    <div class="col-8 mb-3">


      <form-button-request v-if="user_id"/>
      <check-availability v-else :car="car.data"/>

    </div>

    <div class="col-4 pt-2 text-end" v-if="!user_id">

      <span class="badge text-bg-primary border shadow-sm rounded-pill handLink mb-1" @click="router.push('/credit-application')">Finance</span> <!-- v-if="car.data.buy_credit" -->
      <span class="badge text-bg-primary shadow-sm rounded-pill mb-1" v-if="car.data.buy_leasing">Leasing</span>
      <span class="badge text-bg-primary border shadow-sm  handLink rounded-pill mb-1 ms-2" data-bs-toggle="modal" data-bs-target="#TradeInModal">Trade-in</span>

    </div>

    <div class="col-12">


      <form-request-modal :car_provider="car.data.provider"/>

      <start-deal v-if="user_type > 0 && car.data.commission_amount > 0" :car="car.data"/>

    </div>


    <!--    car.data.car_requested && car.data.car_requested.transfer && car.data.car_requested.transfer.id-->
    <div class="row mb-3 mt-3" v-if="car.data.car_requested || car.data.car_requested && car.data.car_requested.transfer && car.data.car_requested.transfer.id">

      <div class="h5">
        Upload required documents:
      </div>


      <div class="col-4">

        <letter-modal/>

      </div>

      <div class="col-4 text-center">

        <nda-modal/>

      </div>

      <div class="col-4">

        <proof-of-funds-modal/>

      </div>

    </div>


  </div>

</template>

<script setup>
//import {useRouter} from "vue-router";
import {Cars} from '@/composables/profile/cars';
import {Languages} from '@/composables/Languages';
import {Helpers} from '@/composables/helpers';
import {userState} from '@/composables/login';

import ButtonAddCar from "@/components/offer/selected_car/ButtonAddCar.vue";
import FormRequestModal from "@/components/offer/request/FormRequestModal.vue";
import LetterModal from "@/components/offer/documents/LetterModal.vue";
import FormButtonRequest from "@/components/offer/request/FormButtonRequest.vue";
import NdaModal from "@/components/offer/documents/NdaModal.vue";
import ProofOfFundsModal from "@/components/offer/documents/ProofOfFundsModal.vue";
import StartDeal from "@/components/offer/broker_to_broker/StartDeal.vue";
import CheckAvailability from "@/components/offer/CheckAvailability.vue";
import SubscriptionModal from "@/components/offer/broker_subscrption/SubscriptionModal.vue";
import VinToSignUp from "@/components/offer/broker_subscrption/VinToSignUp.vue";




const {car} = Cars()

//defineProps(["car"]);

//const router = useRouter()

const {convertToShortForm} = Helpers()

const {selected_language, car_languages} = Languages()

const {user_id, user_type} = userState()
</script>