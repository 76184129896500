import {reactive, ref} from "vue";
import axios from "axios";
import {userState} from '@/composables/login';

const {user_id} = userState()

const reveal = ref({})

const vin = ref({})


export const Reveal = () => {

    const storeReveal = async (prepare: object) => {

        try {

            try {

                await axios.post('/api/reveal', prepare)

            } catch (e: any) {

                console.log(e)
            }


        } catch (e: any) {

            console.log(e)
        }
    }

    const getNumber = async (reveal_id: number, car_id: number) => {

        try {
            await axios.post('/api/reveal/' + reveal_id).then((response) => {

                if(response && response.data){

                    reveal.value = response.data

                    storeReveal({user_id: user_id.value, revealed_id: reveal_id, car_id: car_id})
                }



            })

        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const getVin = async (car_id: number) => {

        try {
            await axios.post('/api/reveal-vin/' + car_id).then((response) => {

                vin.value = response.data
            })

        } catch (error) {

            console.log('>>> ' + error);
        }
    }


    return {
        getNumber, reveal,

        vin, getVin
    }

}