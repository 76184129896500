<template>

  <div class="row mt-3" v-if="car.data.price">

    <div class="col-6 text-center">


      <div class="card rounded-4 bg-light">

        <div class="card-body" v-if="car.data.price">

          <span class="text-muted fw-lighter">
<!--              {{ car_languages[selected_language].price }}-->

            PRICE FOR SALE

          </span><br>
          <span class="h3 card-title mt-2 oswald text-danger fw-bold">${{ car.data.price.toLocaleString('en-US') }}</span>
        </div>
        <div class="card-body" v-else>
          {{ car_languages[selected_language].price_by_request }}
        </div>


      </div>

      <small class="text-muted" v-if="car.data.price">
        <small class="comfortaa"> USDT </small>
      </small>


    </div>

    <span v-if="checkBTwoB && car.data.commission_amount" class="h5 ms-2 d-block mt-3">B2B: partner conditions</span>

    <div v-if="checkBTwoB && car.data.commission_amount"
         class="row bg-light border rounded border-danger text-center me-1 ms-1  mb-3">

      <div class="col-6 pt-2 pb-2" v-if="car.data.commission_amount">

        <h5 class="text-muted">Commission</h5>

        <span class="h3 oswald text-danger">
          ${{ car.data.commission_amount.toLocaleString('en-US') }}
      </span>
      </div>

      <div class="col-6 mt-3 h5" v-if="car.data.commission_not_included">
        Commission not included
      </div>

      <div class="col-6 mt-3 text-start" v-if="car.data.description_btwob">
        <p v-html="car.data.description_btwob.replace(/\n/g, '<br>')"></p>
      </div>

    </div>


    <div class="row mt-3" v-if="car.data.buy_credit && car.data.finance_interest">

      <div class="col-12">

        <finance-info/>

      </div>

    </div>


    <leasing-info v-if="car.data.buy_leasing && car.data.lease_term_months"/>


    <div class="col-6 text-center" v-if="checkBTwoB && car.data.msrp_price">

      <div class="card rounded-4 bg_price h-100">

        <div class="card-body">

          MSRP<br>
          <span class="h3 card-title oswald mt-2">${{ car.data.msrp_price.toLocaleString('en-US') }}</span>
        </div>
      </div>

    </div>


    <div class="mt-3 col-12" v-if="user_id && car.data.vin">

      VIN: {{ car.data.vin }}
    </div>

    <div class="mt-3 col-12" v-if="!user_id && car.data && car.data.vin">

      To view all details, please login.
    </div>

  </div>

</template>
<script setup>
import {Cars} from '@/composables/profile/cars';
import {userState} from "@/composables/login";
import FinanceInfo from "@/components/offer/FinanceInfo.vue";
import LeasingInfo from "@/components/offer/LeasingInfo.vue";
import {Helpers} from "@/composables/helpers";


const {car} = Cars()

const {checkBTwoB} = Helpers()

const {user_id, user_type} = userState()
</script>