import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
//import ListCarsView from '../views/profile/ListCarsView.vue'
import OfferView from '../views/OfferView.vue'
//import ProfilePublic from '../views/profile/ProfilePublic.vue'
//import BoardView from '../views/BoardView.vue'
//import BoardWantedView from '../views/BoardWantedView.vue'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        // {
        //   path: '/',
        //   name: 'page',
        //   component: HomeView
        // },
        {
            path: '/:page?/:id(\\d+)?',
            name: 'page',
            component: HomeView,
            props: true
        },
        {
            path: '/:id(\\d+)',
            name: 'profile_public',
            component: () => import('@/views/profile/ProfilePublic.vue'),
            props: true
        },
        {
            path: '/board/:id(\\d+)?',
            name: 'board',
            component: () => import('@/views/BoardView.vue'),
            props: true
        },
        {
            path: '/board-wanted/:id(\\d+)',
            name: 'board-wanted',
            component: () => import('@/views/BoardWantedView.vue'),
            props: true
        },
        {
            path: '/list-cars/:id(\\d+)?', // profile group
            name: 'list-cars',
            component: () => import('@/views/profile/ListCarsView.vue'),
            props: true
        },
        {
            path: '/bookings/:id(\\d+)?', // profile group
            name: 'bookings',
            component: () => import('@/views/profile/CarsBooking.vue'),
            props: true
        },
        {
            path: '/cars-wanted/:id(\\d+)?', // profile group
            name: 'cars-wanted',
            component: () => import('@/views/profile/CarWanted.vue'),
            props: true
        },
        {
            path: '/off-market/:id(\\d+)?', // profile group
            name: 'off-market',
            component: () => import('@/views/profile/OffMarketView.vue'),
            props: true
        },
        {
            path: '/offer/:id(\\d+)',
            name: 'offer',
            component: OfferView,
            props: true
        },
        {
            path: '/profile',
            name: 'profile',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/profile/ProfileView.vue')
        },
        {
            path: '/chats',
            name: 'chats',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/profile/ChatList.vue')
        },
        {
            path: '/credit-application',
            name: 'credit-application',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/profile/CreditApplication.vue'),
            props: true
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/AboutView.vue')
        },

        {
            path: '/terms',
            name: 'terms',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/content/TermsAndCondition.vue')
        },

        {
            path: '/privacy',
            name: 'privacy',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/content/TermsAndCondition.vue')
        },

        {
            path: '/mission',
            name: 'mission',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/content/OurMission.vue')
        },

        {
            path: '/blog/:id(\\d+)',
            name: 'blog',
            component: () => import('@/views/blog/BlogView.vue'),
            props: true
        },

        {
            path: '/blog/add',
            name: 'blog-add',
            component: () => import('@/views/blog/AddBlogView.vue'),
        },

        {
            path: '/cars-looking',
            name: 'cars-looking',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/content/CarsLookingFastBoarding.vue')
        },


        {
            path: '/ambassador',
            name: 'ambassador',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/profile/ambassador/landingView.vue')
        },
        {
            path: '/ambassador/agreement',
            name: 'ambassador_agreement',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/profile/ambassador/AgreementView.vue')
        },

        {
            path: '/ambassador/school',
            name: 'ambassador_school',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/profile/ambassador/school/IndexMenu.vue')
        },

        {
            path: '/ambassador/course',
            name: 'ambassador_course',

            children: [
                {
                    path: 'instagram',
                    component: () => import('@/views/profile/ambassador/school/InstagramCourse.vue')
                },

                {
                    path: 'linkedin',
                    component: () => import('@/views/profile/ambassador/school/LinkedinCourse.vue')
                },

                {
                    path: 'scripts',
                    component: () => import('@/views/profile/ambassador/school/ScriptsInvitation.vue')
                },

                {
                    path: 'facebook-marketplace',
                    component: () => import('@/views/profile/ambassador/school/FacebookMarketplace.vue')
                },
            ]
        },

        {
            path: '/chat-room/:id(\\d+)',
            name: 'chat_room',
            component: () => import('@/views/ChatRoomsView.vue'),
            props: true
        },


        { // old (remove)
            path: '/landing',
            name: 'landing',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/LandingView.vue')
        },


        { // old (remove)
            path: '/landing-inspection',
            name: 'landing-inspection',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/landing/InspectionView.vue')
        },

        { // old (remove)
            path: '/test/:id(\\d+)?',
            name: 'test',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/TestSecond.vue'),
            props: true
        },

        {
            path: '/photo-service',
            name: 'photo-service',
            component: () => import('@/views/content/PhotographedCar.vue'),
        },

        // {
        //   path: '/sign',
        //   name: 'sign',
        //   // route level code-splitting
        //   // this generates a separate chunk (About.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import('../views/SignView.vue')
        // },

        // 404
        {path: "/:pathMatch(.*)*", name: 'not-found', redirect: "/"},
        //{path: "/:catchAll(.*)", name: 'not-found', redirect: "/"},
    ]
})

export default router
