<template>

  <button class="btn btn-primary rounded-4 mt-1 me-3 mb-3 comfortaa animate__animated animate__fadeInDown"
          :disabled="disable"
          @click="disable = true, file_input.click()">Add Images
  </button>

  <input ref="file_input" @change="fileAttached" multiple="multiple"
         accept=".jpeg,.jpg,.png" class="form-control d-none" type="file">

  <span v-if="uploading" class="spinner-border spinner-border-sm ms-2 mt-2 d-inline-block" role="status">
    <span class="visually-hidden">Loading...</span>
  </span>

  <template v-if="uploaded_images.length > 0">

<!--    <br><br>-->
<!--    Request Sent!-->
    <br>
    <img :src="'/storage/trade_in/' + i"
         class="img-fluid ms-2 mb-2 rounded-4 float-start" width="140"
         v-for="i in uploaded_images" :key="i">

  </template>

</template>

<script setup>
import {ref} from "vue";
import axios from "axios";

import {TradeIn} from "@/components/offer/trade_in/trade_in";


const {trade_id} = TradeIn()



const uploading = ref(false)
const file_input = ref()
const file_type = ref(0)
const file_attached = ref(false)
const disable = ref(false)


const uploaded_images = ref([])


const saveImages = async () => {

  uploading.value = true


  for (let i = 0; i < Array.from(file_input.value.files).length; i++) {

    const form = new FormData();
    form.append('file', file_input.value.files[i]);
    //form.append('file_name', file_input.value.files[i].name);
    form.append('trade_id', trade_id.value);
    form.append('file_type', file_type.value);


    await axios.post('/api/trade-images',
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
    ).then((response) => {

      uploaded_images.value.push(response.data)
    });
  }


  uploading.value = false

  file_input.value.value = ''

  file_attached.value = false

  //trade_id.value = {}

  disable.value = false

  //await getProductsFiles(product.value.id)
}

const fileAttached = e => {

  if (e.target.files && e.target.files[0]) {

    //console.log(e.target.files[0])
    file_attached.value = true

    saveImages()

  } else {

    file_attached.value = false
  }

}
</script>