<template>

  <div class="ps-1">
    <a href="https://chat.whatsapp.com/Drtp58Fr87rFmazsD5kShm" target="_blank" class="text-light">
      {{languages[selected_language].subscribe.title}}</a> <img src="/img/icons/whatsapp.svg" alt="whatsapp group" width="28" height="28">
  </div>

<!--  <small class="ms-1">-->
<!--    {{languages[selected_language].subscribe.prefix}}-->
<!--  </small>-->

</template>

<script setup>
import {Languages} from "@/composables/Languages";

const {languages, selected_language} = Languages()
</script>