<template>

<!--  {{car.data.photos}}-->

<!--  {{big_carousel_id}}-->

  <div id="carouselBigFade" class="carousel slide carousel-fade" data-bs-ride="carousel">

    <div class="carousel-inner">


      <div class="carousel-item" :class="big_carousel_id == p.pfile ? 'active' : ''" :key="p.id" v-for="(p) in car.data.photos">
        <img :src="'https://hotautomarket.com/storage/cars/' + p.pfile" class="d-block w-100 rounded-4 img-fluid"
             loading="lazy"
             :alt="car.data.model.model + ' ' +car.data.model_addition ? car.data.model_addition : ''">
      </div>

    </div>

    <button class="carousel-control-prev" type="button" data-bs-target="#carouselBigFade" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselBigFade" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

</template>

<script setup>
import {Cars} from "@/composables/profile/cars";

const {car, big_carousel_id} = Cars()

</script>