<template>

<!--  {{cars}}-->
<!--  {{search}}-->

<!--  {{cars_models}}-->

  <div class="animate__animated animate__fadeInDown" v-if="search.data.text">
<!--    <label for="modelFormControlInput" class="form-label">Model</label>-->
    <input type="search" @click="router.push('/')" @change="router.push('/')" v-model="search_model" class="form-control" list="model_list" id="modelFormControlInput" placeholder="Model">
  </div>

  <datalist v-if="getModelsByBrand" id="model_list" class="w-100">
    <option :key="'model_' + i" v-for="(c, i) in getModelsByBrand.models">{{ c.model }}</option>
  </datalist>



<!--    {{getModelsByBrand.models}}-->



</template>

<script setup>
import {computed, watch} from "vue";
import {Cars} from "@/composables/profile/cars";
import {useRouter} from 'vue-router';

const {search, cars_models, search_model, getListCars} = Cars()

const getModelsByBrand = computed(() => {

  return cars_models.data && cars_models.data.length > 0 ? cars_models.data.find((el) => {

    //console.log(el)

    if(el.id && el.name === search.data.text){

      return el.name
    }
  }) : null
})

const router = useRouter()

watch(search_model, () => { // newVal, oldVal
      //console.log('Router:', newVal, oldVal)

      if (search_model.value) {
        router.push('/')
      }

      getListCars(search.data.text)

      //scroll to top
      //window.scrollTo(0, 0)

      document.getElementById('search_result').scrollIntoView();

    } //, {deep: true, immediate: true}
);
</script>