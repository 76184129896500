<template>

  <span v-if="search.data && search.data.text"
      class="btn btn-primary rounded-pill btn-sm mt-2 mb-2"
        @click="getListCars(search.data.text), searchActivate()">
   &nbsp; SEARCH &nbsp;
  </span>

  <br>

</template>

<script setup>
import {Cars} from "@/composables/profile/cars";

const {getListCars, search} = Cars()

const searchActivate = () => {

  document.getElementById('search_result').scrollIntoView({
    behavior: "smooth", // Adds smooth scrolling

  });
}
</script>