<template>

  <br>
  <span :class="selected_year == year ? 'text-bg-danger' : 'text-bg-light'"
      class="badge rounded-pill me-2 mb-2 animate__animated animate__fadeInDown handLink"
        :key="'n_' + index"
        v-for="(year, index) in years">
    <span @click="search_year = year, selected_year = year"> {{year}} </span>
  </span>


  <br>

</template>

<script setup>
import {Cars} from "@/composables/profile/cars";
import {ref} from "vue";

const {search_year} = Cars()

const selected_year = ref(0)

const years = Array.from({ length: parseInt(new Date().getFullYear()) - 2010 + 1 }, (_, index) => 2025 - index);

// const years = computed(() => {
//   const startYear = 2025;
//   const endYear = 2005;
//   return Array.from({ length: endYear - startYear - 1 }, (_, i) => startYear + i);
// });


</script>