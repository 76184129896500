import {ref} from "vue";
import axios from "axios";


//const like = ref(false)
const subscription = ref({
    user_id: 0,
    // date_start: Date.now(),
    // date_end: Date.now(),
})


export const Subscription = () => {

    const infoSubscription = async () => {


        try {

            await axios.get('/api/subscription').then( (response) => {

                subscription.value = response.data
            })




        } catch (error) {

            console.log('>>> ' + error);
        }

    }

    const activateTrailSubscription = async () => {


        try {

            await axios.post('/api/subscription', subscription.value)




        } catch (error) {

            console.log('>>> ' + error);
        }
    }

    const visitPaymentLink = async () => {

        try {

            await axios.post('/api/subscription-visit-link', subscription.value)




        } catch (error) {

            console.log('>>> ' + error);
        }

    }

    return{
        subscription, infoSubscription,
        activateTrailSubscription, visitPaymentLink
    }
}