<template>

<!--  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">-->
<!--    Launch demo modal-->
<!--  </button>-->


  <!-- MD Big Photo Modal -->
  <div class="modal fade" id="bigPhotoModal" tabindex="-1" aria-labelledby="bigPhotoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content rounded-4">

        <div class="modal-header">
          <h1 class="modal-title fs-5 comfortaa text-muted" id="bigPhotoModalLabel" v-if="car.data && car.data.model">{{car.data.model.brand.name}} {{car.data.model.model}} {{car.data.model_addition ? car.data.model_addition : ''}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <md-carousel-big/>

        </div>

<!--        <div class="modal-footer">-->
<!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
<!--          <button type="button" class="btn btn-primary">Save changes</button>-->
<!--        </div>-->
      </div>
    </div>
  </div>



</template>

<script setup>
import {Cars} from '@/composables/profile/cars';

const {car} = Cars()


import MdCarouselBig from "@/components/offer/MdCarouselBig.vue";
</script>