<template>

  <template v-if="suggestions && suggestions.models">

      <span class="badge rounded-pill text-bg-light handLink me-2 mb-2 animate__animated animate__fadeInDown"
            @click="search_guess = s.model, search.data.text = s.brand.name, search_model = s.model"
            :key="'s_' + i" v-for="(s, i) in suggestions.models">
        {{s.brand.name}} {{s.model}}
      </span>

  </template>

  <template v-if="suggestions && suggestions.brands ">

      <span class="badge rounded-pill text-bg-light handLink"
            @click="search_guess = s.name, search.data.text = s.name, search_model = ''"
            :key="'s_' + i" v-for="(s, i) in suggestions.brands">
        {{s.name}}
      </span>

  </template>

  <price-range v-if="price_active"/>
  <years-list v-if="years_active"/>
  <search-button v-if="suggestions.models && (price_active || years_active)"  />


</template>

<script setup>
import {Searching} from '@/components/search_new/search';
import {Cars} from "@/composables/profile/cars";
import PriceRange from "@/components/search_new/PriceRange.vue";
import YearsList from "@/components/search_new/YearsList.vue";
import SearchButton from "@/components/search_new/SearchButton.vue";

const {search, search_model} = Cars()

const {suggestions, search_guess, price_active, years_active} = Searching()
</script>